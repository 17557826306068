import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import styled from "styled-components"

const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
`;

const StyledH1 = styled.h1`
  font-size: 2rem;
  font-weight: 700;
`;

const Paragraph = styled.p`
  
`;

const StyledLink = styled(props => <Link {...props}/>)`
  color: rgba(230, 50, 50, 0.86);
`;

const NotFoundPage = () => (
  <NotFound>
    <SEO title="404: Not found" />
    <StyledH1>Błąd 404</StyledH1>
    <Paragraph>Nie odnaleziono podanej strony</Paragraph>
    <StyledLink to={"/"}>Powrót</StyledLink>
  </NotFound>
)

export default NotFoundPage
